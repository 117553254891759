import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import Content from "../layout/Content/Content";

const Container = Styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 24rem;
  padding-bottom: 24rem;
`;

const Error = Styled.h3`
  font-size: 4rem;
  text-align: center;
  margin: 0 0 0.5rem 0;

  ${Media.greaterThan("medium")`
    font-size: 8rem;
  `}
`;

const Text = Styled.p`
  text-align: center;
  margin: 0;
`;

const NotFound = () => (
  <Container>
    <Error>404</Error>
    <Text>Not found ¯\_(ツ)_/¯</Text>
  </Container>
);

export default NotFound;
