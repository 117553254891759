import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout/Layout";

import NotFound from "../components/security/NotFound";
import Meta from "../components/meta";

export default () => (
  <Layout>
    <Meta
      title="Not found"
      description="The page you were looking for was not found"
      url="/404/"
    />

    <NotFound />
  </Layout>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
